import React, { useEffect, useState } from 'react';
import "../styles/Footer.css";
import logo from "../assets/logo-3.png";
import linkedin from "../assets/linkedin.png"
import facebook from "../assets/facebook.png";
import twitter from "../assets/twitter.png";
import phone from "../assets/phone.png";
import emailIcon from "../assets/email.png";
import address from "../assets/address.png";
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";

export default function Footer() {
    const [isTablet, setIsTablet] = useState(false);
    const [FormMessage, setFormMessage] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("");
    const [fullName, setFullName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    const databaseURL = 'https://unitrestech-backend.onrender.com'

    const handleNo = (value) => {
        setPhoneNumber(value);
        console.log(value);
    };

    const handleEmail = async (formData) => {
        try {
            const response = await fetch(`${databaseURL}/email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                alert('Error submitting form');
            }
        }
        catch (error) {
            console.error('Error:', error);
            alert('Error submitting form');
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            name: fullName,
            email,
            phone: phoneNumber,
            company_name: companyName,
            message
        };

        console.log(formData)

        try {
            const response = await fetch(`${databaseURL}/api/form`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {

                setFormMessage("Thank you for reaching out! We value your enquiry and our customer service team shall respond to you shortly.")
                setFullName("");
                setCompanyName("");
                setEmail("");
                setPhoneNumber("");
                setMessage("");
                handleEmail(formData);
            }
            else {
                alert('Error submitting form');
            }
        }
        catch (error) {
            console.error('Error:', error);
            alert('Error submitting form');
        }
    };

    let handleCooseuse = () => {
        navigate('/');
        setTimeout(() => {
            const section = document.getElementById('section-4-container');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }, 500);
    }

    useEffect(() => {
        const handleResize = () => {
            setIsTablet(window.innerWidth < 1282);
        };
        handleResize();
    }, []);

    return (
        <div id='main-cont'>
            <div id='foot-sec1'>
                <div id='sec1-prt1'>
                    <div>
                        <h1>Take your next step with Unitres Tech</h1>
                        <p>Let us know your areas of interest so that we can serve you better.</p>
                    </div>
                    <div>
                        <div>
                            <img src={phone} alt="" />
                            <div>
                                <p>Call Today <br /> +91 9820638422</p>
                            </div>
                        </div>
                        <div>
                            <img src={emailIcon} alt="" />
                            <div>
                                <p>Email <br /> info@unitrestech.com</p>
                            </div>
                        </div>
                        <div>
                            <img src={address} alt="" />
                            <div>
                                <p>Office <br /> A/706 Sagar Tech Plaza, Above Bajaj Show Room, Andheri Kurla, Mumbai - 400072</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='sec1-prt2'>
                    <form onSubmit={handleSubmit}>
                        <h3>Full Name*</h3>
                        <input
                            type="text"
                            placeholder='Enter Your Full Name'
                            className='input-tag-footer'
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                        />

                        <h3>Company Name*</h3>
                        <input
                            type="text"
                            placeholder='Enter Your Company Name'
                            className='input-tag-footer'
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            required
                        />

                        <h3>Your Email*</h3>
                        <input
                            type="email"
                            placeholder='example@youremail.com'
                            className='input-tag-footer'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        <h3>Your Phone Number*</h3>
                        <div id='phone'>
                            <PhoneInput
                                inputStyle={{ width: "105%", height: "50px", borderRadius: "15px", borderLeft: "15px", paddingLeft: "70px", fontSize: "17px" }}
                                containerStyle={{ margin: '0px' }}
                                buttonStyle={{ padding: "0px 10px" }}
                                country={"us"}
                                value={phoneNumber}
                                onChange={handleNo}
                                inputProps={{
                                    required: true,
                                }}
                            />
                        </div>

                        <h3>Message*</h3>
                        <textarea
                            placeholder="Enter your Message"
                            id='inputTextfooter'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                        <p>{FormMessage}</p>

                        <input
                            type="submit"
                            id='submit-footer-form'
                            value="Send Message"
                            style={{ fontFamily: "Plus Jakarta Sans", fontSize: "18px", fontWeight: "300" }}
                        />
                    </form>
                </div>
            </div>
            <div id='foot-sec2'>
                <div>
                    <img src={logo} alt="" />
                    <h2>Driving Technology Driven Intelligent Outcomes</h2>
                </div>
                <div>
                    <hr />
                </div>
                {
                    isTablet ? (
                        <div id='istab-view'>
                            <div className='foot-tabs'>
                                <h3>Resources</h3>
                                <p onClick={() => { navigate("/") }}>Home</p>
                                <p onClick={() => { navigate("/about") }}>About Us</p>
                                <p onClick={handleCooseuse}>Insight</p>
                                <p>Blog</p>
                                <p onClick={() => { navigate("/careers") }}>Careers</p>
                                <p onClick={handleCooseuse}>Why choose us</p>
                            </div>
                            <div className='foot-tabs'>
                                <h3>Services</h3>
                                <p onClick={() => { navigate("/service/application-modernization") }}>Application Modernization</p>
                                <p onClick={() => { navigate("/service/cloud-engineering") }}>Cloud Engineering</p>
                                <p onClick={() => { navigate("/service/intelligent-automation") }}>Intelligent Automation</p>
                                <p onClick={() => { navigate("/service/data-engineering-analytics") }}>Data Engineering & Analytics</p>
                            </div>
                            <div className='foot-tabs'>
                                <h3>Reach Us</h3>
                                <div>
                                    <a href="https://www.linkedin.com/company/unitres/" target='blank'>
                                        <img src={linkedin} alt="" />
                                    </a>
                                    <a href="https://x.com/unitrestech" target='blank'>
                                        <img src={twitter} alt="" />
                                    </a>
                                    <a href="">
                                        <img src={facebook} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='foot-tabs'>
                                <h3>Resources</h3>
                                <p onClick={() => { navigate("/") }}>Home</p>
                                <p onClick={() => { navigate("/about") }}>About</p>
                                <p onClick={handleCooseuse}>Insight</p>
                                <p>Blog</p>
                                <p onClick={() => { navigate("/careers") }}>Careers</p>
                                <p onClick={handleCooseuse}>Why choose us</p>
                            </div>
                            <div className='foot-tabs'>
                                <h3>Services</h3>
                                <p onClick={() => { navigate("/service/application-modernization") }}>Application Modernization</p>
                                <p onClick={() => { navigate("/service/cloud-engineering") }}>Cloud Engineering</p>
                                <p onClick={() => { navigate("/service/intelligent-automation") }}>Intelligent Automation</p>
                                <p onClick={() => { navigate("/service/data-engineering-analytics") }}>Data Engineering & Analytics</p>
                            </div>
                            <div className='foot-tabs'>
                                <h3>Reach Us</h3>
                                <div>
                                    <a href="https://www.linkedin.com/company/unitres/" target='blank'>
                                        <img src={linkedin} alt="" />
                                    </a>
                                    <a href="https://x.com/unitrestech" target='blank'>
                                        <img src={twitter} alt="" />
                                    </a>
                                    <a href="">
                                        <img src={facebook} alt="" />
                                    </a>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
            <div id='foot-sec3'>
                <p>Copyright © Unitres {year}</p>
                <p>Privacy Policy</p>
            </div>
        </div>
    );
}
