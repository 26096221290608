import React, { useState, useEffect, useRef } from 'react';
import "../styles/Navbar.css";
import logo from "../assets/logo-2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import AppIcon from "../assets/Navbar/AppIcon.png";
import AppIcon2 from "../assets/Navbar/AppIcon2.png";
import CldIcon from "../assets/Navbar/CldIcon.png";
import CldIcon2 from "../assets/Navbar/CldIcon2.png";
import DataEng from "../assets/Navbar/DataEngIcon.png";
import DataEng2 from "../assets/Navbar/DataEngIcon2.png";
import InteAuto from "../assets/Navbar/InteAuto.png";
import InteAuto2 from "../assets/Navbar/InteAuto2.png";

const Navbar = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const dropdownRef = useRef(null);

    const [AppMon, setAppMon] = useState(false);
    const [DataEngg, setDataEng] = useState(false);
    const [CldEng, setCldEng] = useState(false);
    const [Inte, setInte] = useState(false);

    const currentUrl = window.location.pathname;
    console.log(currentUrl);

    const toggleDropdown = (event) => {
        event.preventDefault();
        setDropdownOpen(!dropdownOpen);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickOutsideDropdown = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    const handleClickOutsideMenu = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };
    const handleScroll = () => {
        setDropdownOpen(false);
    };

    useEffect(() => {
        if (currentUrl === "/service/application-modernization") {
            setAppMon(true);
        } else {
            setAppMon(false);
        }

        if (currentUrl === "/service/cloud-engineering") {
            setCldEng(true);
        } else {
            setCldEng(false);
        }

        if (currentUrl === "/service/intelligent-automation") {
            setInte(true);
        } else {
            setInte(false);
        }

        if (currentUrl === "/service/data-engineering-analytics") {
            setDataEng(true);
        } else {
            setDataEng(false);
        }

        document.addEventListener('mousedown', handleClickOutsideDropdown);
        document.addEventListener('mousedown', handleClickOutsideMenu);
        window.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDropdown);
            document.removeEventListener('mousedown', handleClickOutsideMenu);
            window.addEventListener('scroll', handleScroll);
        };
    }, [currentUrl]);

    return (
        <div className='navbar'>
            <div className='logo' onClick={() => { navigate('/') }}>
                <img src={logo} alt="Logo" />
            </div>
            <div className='hamburger' onClick={toggleMenu}>&#9776;</div>
            <div id="forflex" className={`tabs ${menuOpen ? 'show' : ''}`} ref={menuRef}>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li className="dropdown" ref={dropdownRef}>
                        <a href="/" className="dropbtn" onClick={toggleDropdown}>
                            Services &nbsp;<FontAwesomeIcon icon={faChevronDown} style={{ transform: dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.2s' }} />
                        </a>
                        <div className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
                            <div className='dropdown-tabs'>
                                <a href="/service/application-modernization">
                                    <img src={AppMon ? AppIcon2 : AppIcon} alt="" />
                                    <div>
                                        <h1>Application Modernization</h1>
                                        <p>Future-Proof Your Business</p>
                                    </div>
                                </a>
                            </div>
                            <hr />
                            <div className='dropdown-tabs'>
                                <a href="/service/cloud-engineering">
                                    <img src={CldEng ? CldIcon2 : CldIcon} alt="" />
                                    <div>
                                        <h1>Cloud Engineering</h1>
                                        <p>Unleash the Power of Cloud-Native Development</p>
                                    </div>
                                </a>
                            </div>
                            <hr />
                            <div className='dropdown-tabs'>
                                <a href="/service/intelligent-automation">
                                    <img src={Inte ? InteAuto2 : InteAuto} alt="" />
                                    <div>
                                        <h1>Intelligent Automation</h1>
                                        <p>Boost Productivity and Cut Costs</p>
                                    </div>
                                </a>
                            </div>
                            <hr />
                            <div className='dropdown-tabs'>
                                <a href="/service/data-engineering-analytics">
                                    <img src={DataEngg ? DataEng2 : DataEng} alt="" />
                                    <div>
                                        <h1>Data Engineering & Analytics</h1>
                                        <p>Accelerated Decision-Making</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/careers">Careers</a></li>
                </ul>
                <ul>
                    <li className='contact' onClick={() => {
                        const footerElement = document.getElementById('sec1-prt2');
                        const elementPosition = footerElement.getBoundingClientRect().top + window.scrollY;
                        const offsetPosition = elementPosition - 68;

                        window.scrollTo({
                            top: offsetPosition,
                            behavior: 'smooth'
                        });

                        toggleMenu();
                        handleScroll()
                    }}>
                        <button>Contact Us</button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Navbar;
