import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Homepage } from '../pages/Homepage'
import { AboutUs } from '../pages/AboutUs'
import { CloudEngg } from '../pages/Services/CloudEngg'
import { AppModernization } from '../pages/Services/AppModernization'
import { DataEngg } from '../pages/Services/DataEngg'
import IntelligentAuto from '../pages/Services/IntelligentAuto'
import Careers from '../pages/Careers'
import ScrollToTop from '../components/ScrollToTop.jsx'
import { UniTradeHomepage } from '../UniAlgoTrade/UniTradeHomepage.jsx'

export const AllRoutes = () => {
    return (
        <ScrollToTop>
            <Routes>
                <Route path='/' element={<Homepage />}></Route>
                <Route path='/about' element={<AboutUs />}></Route>
                <Route path='/service/cloud-engineering' element={<CloudEngg />}></Route>
                <Route path='/service/application-modernization' element={<AppModernization />}></Route>
                <Route path='/service/data-engineering-analytics' element={<DataEngg />}></Route>
                <Route path='/service/intelligent-automation' element={<IntelligentAuto />}></Route>
                <Route path='/careers' element={<Careers />}></Route>
                <Route path='/UniAlgoTrade' element={<UniTradeHomepage />}></Route>
            </Routes>
        </ScrollToTop>
    )
}
