import { useState } from 'react'
import '../../styles/CircleInfo/CircleDataEngineering.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from '../../components/Carousel.jsx'
import { DataEngineering as slides } from '../../data/DataEngineering.js'

export const CircleDataEngineering = () => {
    console.log(slides);
    const [selectedItem, setSelectedItem] = useState(0);
    const [valuedata, setvaluedata] = useState(1);
    const [colordata, setcolordata] = useState({
        1: true,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false
    })

    const handleChange = (index) => {
        setSelectedItem(index);
        setvaluedata(index + 1);
        setcolordata({
            1: index === 0,
            2: index === 1,
            3: index === 2,
            4: index === 3,
            5: index === 4,
            6: index === 5
        });
    }

    const handleNext = () => {
        setSelectedItem((prev) => (prev + 1) % slides.length);
        let nextIndex = (selectedItem + 1) % slides.length;
        setvaluedata(nextIndex + 1);
        setcolordata({
            1: nextIndex === 0,
            2: nextIndex === 1,
            3: nextIndex === 2,
            4: nextIndex === 3,
            5: nextIndex === 4,
            6: nextIndex === 5
        });
    };

    const handlePrev = () => {
        setSelectedItem((prev) => (prev - 1 + slides.length) % slides.length);
        let prevIndex = (selectedItem - 1 + slides.length) % slides.length;
        setvaluedata(prevIndex + 1);
        setcolordata({
            1: prevIndex === 0,
            2: prevIndex === 1,
            3: prevIndex === 2,
            4: prevIndex === 3,
            5: prevIndex === 4,
            6: prevIndex === 5
        });
    };

    return (
        <div>
            <div className='DEApp-modern-Circle'>
                <div className="DEmainDiagram">
                    <div onClick={() => handleChange(0)} className='DECircleiteam'><p style={{ color: (colordata[1]) ? "White" : "black", background: (colordata[1]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>STRATEGIES</p></div>
                    <div className="DEdiagram-component" style={{ gap: "600px" }}>
                        <div onClick={() => handleChange(5)} className='DECircleiteam'><p style={{ color: (colordata[6]) ? "White" : "black", background: (colordata[6]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>MAINTAIN</p></div>
                        <div onClick={() => handleChange(1)} className='DECircleiteam'><p style={{ color: (colordata[2]) ? "White" : "black", background: (colordata[2]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>OPTIMISE</p></div>
                    </div>
                    <div className="DEdiagram-component" style={{ gap: "600px" }}>
                        <div onClick={() => handleChange(4)} className='DECircleiteam'><p style={{ color: (colordata[5]) ? "White" : "black", background: (colordata[5]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>PUBLISH</p></div>
                        <div onClick={() => handleChange(2)} className='DECircleiteam'><p style={{ color: (colordata[3]) ? "White" : "black", background: (colordata[3]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>SECURE</p></div>
                    </div>
                    <div onClick={() => handleChange(3)} className='DECircleiteam'><p style={{ color: (colordata[4]) ? "White" : "black", background: (colordata[4]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>DEPLOY</p></div>

                    <div className='DEcircle_dig' >
                        {selectedItem !== null ? (
                            <div>
                                <h1>{slides[selectedItem].title}</h1>
                                <p>{slides[selectedItem].desc}</p>
                                {slides[selectedItem].content.map((elem, idx) => (
                                    <ul key={idx}>
                                        <li>{elem}</li>
                                    </ul>
                                ))}
                                <div className="DEnavigation-buttons">
                                    <button onClick={handlePrev}><FontAwesomeIcon icon={faArrowRight} rotation={180} /></button>
                                    <p>{valuedata}</p>
                                    <button onClick={handleNext}><FontAwesomeIcon icon={faArrowRight} /></button>
                                </div>
                            </div>
                        ) :
                            <div>
                                <h1>Secure</h1>
                                <ul>
                                    <li>Inventory and Analysis: Take stock of existing applications, their architecture, dependencies, and performance.</li>
                                    <li>Business Alignment: Understand current and future business goals to ensure modernization efforts align with organizational objectives.</li>
                                    <li>Risk Analysis: Identify potential risks and challenges associated with the modernization process.</li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='DECarousel'>
                <Carousel slides={slides} />
            </div>
        </div>
    );
}