import React, { useState,useEffect } from 'react'
// import data from '../../data/Career.json'
import arrowUp from '../../assets/Career/Career/arrowUp.png'
import arrowSide from '../../assets/Career/Career/arrowSide.png'
import '../../styles/Career/SubCareer.css'
import axios from 'axios'

export const Career = () => {
    const [alldata, setalldata] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://unitrestech-backend.vercel.app/api/career');
            // console.log(response.data);
            setalldata(response.data);
          }
          catch (error) {
            console.log(error);
          }
        };
    
        fetchData();
      }, []);

    const togglebool = (id) => {
        const updatedData = alldata.map((item) => {
            if (item.id === id) {
                return { ...item, isShow: true };
            }
            else{
                return { ...item, isShow: false };
            }
        });
        setalldata(updatedData);
    };
    
  return (
    <div className='subcareerContainer'>
        <div className='subcareerContainer-one'>
            {
            alldata?.map((elem)=>{ 
                // console.log(elem.isOpen)
                return (
                    elem.isOpen &&
                    <div key={elem.id} className="careerItem" onClick={()=>(togglebool(elem.id))} style={{backgroundColor:elem.isShow?"#F3F7FF":"#FFFFFF",borderRight:elem.isShow?"4px solid #0B63E5":"1px solid #7A7D9C" }} >
                        <div className="careerContent" >
                            <p>{elem.name}</p>
                            <p>{elem.Type} - <span>{elem.site}</span></p> 
                            <p>{elem.Job_Description}</p>
                        </div>
                        <div className="careerArrow">
                            {elem.isShow
                            ?
                            <img src={arrowUp} alt="arrowUp" />
                            :
                            <img src={arrowSide} alt="arrowSide" />
                            }
                        </div>
                    </div>
                ) 
            })
            }
        </div>
        <div className='subcareerContainer-two'>
            <hr  className='Linetag'/>
            {alldata?.map((elem)=>{
                // console.log(elem.Responsibilities)
                return (elem.isShow &&
                    <div key={elem.id}>
                        <p className='JobDescription'>Job Description: <span>{elem.name}</span></p>
                        <p className='JobLocation'>Location: <span>{elem.location}</span></p>
                        <p className='JobLocation'>Job Positions: <span>{elem.Job_Positions}</span> </p>
                        <h6 className='Jobresponsibility'> <p> Responsibilities: </p> 
                            {
                                // console.log(JSON.parse(elem.Responsibilities))
                                JSON.parse(elem.Responsibilities).map((elem,idx)=>{
                                    return <ul key={idx}>
                                        <li>{elem[idx+1]}</li>
                                    </ul>
                                })
                            }
                        </h6>
                        <h6 className='JobSkill'>
                        <p> Skills Required: </p>
                            {
                                JSON.parse(elem.Candidate_Requirements).map((elem,idx)=>{
                                    // console.log(elem);
                                    return <ul key={idx}>
                                        <li>{elem[idx+1]}</li>
                                    </ul>
                                })
                            }
                        </h6>
                        {/* <Button ele={CareerBtnProps} /> */}
                        <h6 className='JobSkill'>How to Apply:</h6>
                            <p>Submit your resume at <strong>info@unitrestech.com</strong>, portfolio, and cover letter explaining your interest and experience.</p>
                    </div>
                )  
            })}
            
        </div>
    </div>
  )
}
